<template>
  <step-template v-if="videos.length > 0" :subStep="subStep">

    <v-row class="mx-5 mb-5 mt-0">
      <v-col :cols="`${!isMobile ? 3 : 12}`">
        <v-img
          max-height="250"
          max-width="250"
          src="/images/GLOBAL/LOGO/BYOCS-logo.png"
          class="m-auto"
        ></v-img>
      </v-col>
      <v-col :cols="`${!isMobile ? 9 : 12}`">
        <v-col cols="12">
        <h2>
          Welcome to the BYOCS Video Library
        </h2> 
        <h5>
          Here you'll find video resources, some of
          which you saw in your patient case, as well as additional topics of
          interest. When you've completed reviewing these resources, click <span class="text-orange"><b>RETURN TO CASE</b></span>.
        </h5>
        <h5 class="mt-10">
          Click on a section below to get started.
        </h5>
      </v-col>
      </v-col>
    </v-row>
    <v-row class="mx-5 mb-5 mt-0">
      <v-col cols="12">
        <v-expansion-panels focusable>
          <v-expansion-panel class="mb-5" v-show="avatarResourcesBySections.length">
            <v-expansion-panel-header>
              Regional Guidelines &amp; Agencies
              <template
                v-slot:actions
              >
                <v-icon color="red lighten-2">
                  mdi-star
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-8">
              <p class="mb-10 text-gray-600"><span class="text-red">*</span><strong> Helpful information can also be found in the Downloadable Resources section of this course.</strong></p>
              <div v-for="(resource, rKey) in avatarResourcesBySections" class="mb-6">
                <h6 class="ma-0">{{ resource.section }} - {{ resource.name }}</h6>
                <p><i>{{resource.label}}</i></p>
                <avatar-resources class="mb-6"
                  buttonName="View Resource Now"
                  :buttonUrl=resource.url
                >
                </avatar-resources>
                <v-divider></v-divider>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-for="(section, sKey) in videosBySections"
            :key="sKey"
            class="mb-5"
          >
            <v-expansion-panel-header>
              {{ sKey }}
              <template
                v-if="sKey === 'Additional Topics of Interest' || sKey === 'The Buzz Series'"
                v-slot:actions
              >
                <v-icon color="red lighten-2">
                  mdi-star
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(video, vKey) in section" :key="vKey">
                <v-col :cols="`${!isMobile ? 4 : 12}`">
                  <v-img
                    :lazy-src="
                      `https://fast.wistia.com/embed/medias/${video.id}/swatch`
                    "
                    max-height="150"
                    max-width="250"
                    :src="videosThumbs[video.id]"
                    @click="openModal(video)"
                  ></v-img>
                </v-col>
                <v-col :cols="`${!isMobile ? 8 : 12}`" class="mb-8">
                  <v-card
                    outlined
                    color="transparent"
                    class="flex-card pb-6"
                    height="100%"
                  >
                    <v-card-text
                      ><h5>
                        {{ video.title_public }}
                      </h5></v-card-text
                    >
                    <v-btn
                      color="red lighten-2 mt-1"
                      dark
                      @click="openModal(video)"
                    >
                      Open Video
                    </v-btn>
                  </v-card>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-btn
        outlined
        color="orange"
        :rounded="true"
        class="border"
        @click="returnToSteps"
        >Return To Case</v-btn
      >
    </v-row>
    <modal-video
      ref="modal-video"
      :user="user"
      :video="modal.video"
      :title="modal.title"
    />
  </step-template>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import ModalVideo from "/src/components/modals/ModalVideo.vue";
import StepTemplate from "/src/components/steps/StepTemplate.vue";
import AvatarResources from "/src/components/buttons/AvatarResources.vue";

export default {
  name: "Library",
  components: {
    ModalVideo,
    StepTemplate,
    AvatarResources
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    videos: {
      type: Array,
      required: true,
    },
    avatars: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      videosSectionsObj: {
        Diabetes: {
          "Patient Profile Selection": [],
          "A1C": [],
          "BMI": [],
          "Comorbidity": [],
          "Clinical Metrics": [],
          "Modifiable Behaviors": [],
          "Social Determinants of Health": [],
          "GLP-1 RA Selection": [],
          "Mitigating Adverse Effects": [],
          "Additional Topics of Interest": [],
        },
        Obesity: {
          "Body Mass Index (BMI)": [],
          "Barriers to Care: The Clinician Edition": [],
          "The Relationship between Comorbidtities and Obesity": [],
          "Patient-Centered Care": [],
          "Modifiable Behaviors": [],
          "Anti-Obesity Medications (AOMs)": [],
          "Multimodal Approach in Addressing Obesity": [],
          "Social Determinants of Health": []
        },
        global: {
          "The Buzz Series": [],
          "Introduction": [],
          "Case Scenario Welcome": [],
          "Language Matters": [],
          "Complex Comorbidity": [],
          "Treatment Strategies": [],
          "Lifestyle Modifications": [],
          "Social Determinants of Health": [],
          "Best Practices Toolkit": [],
          "Conclusion and Key Takeaways": []
        }
      },
      videosBySections: {},
      videosThumbs: {},
      avatarResourcesBySections: {},
      modal: {
        video: {},
        title: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      case: (state) => state.case,
    }),
    videosSections() {
      return this.videosSectionsObj[this.$route.params.case];
    },
  },
  watch: {
    videos(val) {
      this.setUpVideosBySections();
    },
  },
  beforeMount() {
    this.setUpVideosBySections();
    this.getAllAvatarResources();
    this.trackingAction("Library Panel Opened");
  },
  beforeDestroy() {
    this.trackingAction("Library Panel Closed");
  },
  methods: {
    ...mapMutations(["setShowLibrary"]),
    ...mapActions(["trackingAction"]),
    returnToSteps() {
      this.setShowLibrary(false);
    },
    openModal(video) {
      this.modal.title = video.title;
      this.modal.video = video;
      this.$refs["modal-video"].setVisible(true);
    },
    getAllAvatarResources() {
      let avatarResourcesList = [];
      for (const aKey in this.avatars) {
        const avatar = this.avatars[aKey];
        if (avatar.variables) {
          let avatarVariables = JSON.parse(avatar.variables);
          if (avatarVariables.resources) {
            for (const rKey in avatarVariables.resources) {
              avatarResourcesList.push(avatarVariables.resources[rKey]);
            }
          }
        }
      }
      console.log(avatarResourcesList);
      this.avatarResourcesBySections = avatarResourcesList;
      //this.setUpAvatarAvatarResourcesBySections();
    },
    setUpAvatarAvatarResourcesBySections () {
      /*
      if (this.avatarResourcesBySections.length === 0) {
        return;
      }
      const avatarResourcesSections = new Object;

      for (const key in this.avatarResourcesBySections) {
        const r = this.avatarResourcesBySections[key];

        // Add Type
        if (avatarResourcesSections[r.type] === undefined) {
          avatarResourcesSections[r.type] = {};
        }

        // Add Section
        if (avatarResourcesSections[r.type][r.section] === undefined) {
          avatarResourcesSections[r.type][r.section] = {};
        }
      }
      this.avatarResourcesBySections = avatarResourcesSections;
      */
    },
    setUpVideosBySections() {
      if (this.videos.length === 0) {
        return;
      }
      
      for (const key in this.videos) {
        const v = this.videos[key];

        if (v.id_case == this.case.id) {
          this.thumbSrc(v.id);
          if (this.videosSections[v.library_section]) {
            this.videosSections[v.library_section].push(v);
          }
        }
      }

      this.videosBySections = this.videosSections;      
    },
    thumbSrc(id) {
      var config = {
        method: "get",
        url: `https://fast.wistia.com/embed/medias/${id}.jsonp`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const self = this;
      axios(config)
        .then(function(response) {
          let data = response.data;
          data = data.split("= ")[1];
          data = data.split(";\nwindow['wistiajsonp")[0];
          data = data.slice(0, -2);
          try {
            const dataString = JSON.parse(data);
            self.$set(
              self.videosThumbs,
              id,
              `${dataString.media.embed_options.stillUrl}?image_crop_resized=480x270`
            );
          } catch (e) {
            // console.log(
            //   "Failed to fetch thumb",
            //   `https://fast.wistia.com/embed/medias/${id}.jsonp`
            // );
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
