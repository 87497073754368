<template>
  <v-row v-if="introduction">
    <v-col
      cols="12"
      md="4"
    >
      <v-card outlined class="flex-card" height="100%">
        <v-card-text>
          <v-img
            max-height="280"
            max-width="280"
            src="/images/GLOBAL/LOGO/BYOCS-logo.png"
            class="m-auto"
          ></v-img>
          <h6 class="pt-5 pb-5 font-weight-medium" v-if="subStep.description">
            <b>Welcome to:<br />
            {{ subStep.name }}</b>
          </h6>
          <h6>Here's what you'll do:</h6>
          <p class="pb-4 font-weight-medium">
            <span
              >1. Treat up to 3 patients<br />2. Spend some time in the
              Library<br />3. Collect some credit!</span
            >
          </p>
        </v-card-text>
        <v-card-actions class="justify-center pb-5 px-10">
          <v-btn
            class="white--text caption font-weight-bold"
            large
            rounded
            block
            color="orange"
            @click="avatarArea()"
          >
            Start treating patients
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <player :video="{id: video_id}" />

      <h5 class="pt-8 pb-6 text-center text-break text-gray-750 font-italic">
        When you complete the video, click Start Treating Patients.
      </h5>
      <p class="text-center font-weight-bold" style="font-size:1.4em;">
        Best experienced on
        <br />
        <v-icon large color="orange">
          mdi-google-chrome
        </v-icon>
        Google Chrome
      </p>
    </v-col>
  </v-row>

  <v-row v-else>
    <v-col
      cols="12"
      md="4"
      align-self="center"
      class="d-flex justify-center"
    >
      <v-card class="flex-card" height="100%">
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-img
                  max-height="280"
                  max-width="280"
                  src="/images/GLOBAL/LOGO/BYOCS-logo.png"
                  class="m-auto"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <h5 class="pt-5 pb-5 text-center">Let's begin your case study!</h5>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center pt-4 pb-1 px-5">
          <v-btn
            class="white--text caption font-weight-bold"
            block
            elevation="2"
            rounded
            color="#ff3f3f"
            @click="randomAvatar()"
          >
            <v-icon right>
              mdi-atom-variant
            </v-icon>
            Use the Randomizer
          </v-btn>
        </v-card-actions>
        <v-card-actions class="d-flex justify-center" style="padding-top:0 !important;color: #999;" >
          or click an avatar
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-card height="100%">
        <v-card-title v-if="subStep.description">
          {{ subStep.description.toUpperCase() }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-card
            outlined
            tile
            color="transparent"
          >
          
            <v-card-text>
              <h6>
                You have 2 options when building your own case:
              </h6>
              <ul class="mb-5">
                <li>
                  <span style="font-weight:bold;">OPTION 1:</span>
                  Select an avatar below and click <span style="color:#ff9800;"><b>CONTINUE</b></span> to fully customize
                  the patient experience.
                </li>
                <li>
                  <span style="font-weight:bold;">OPTION 2:</span>
                  Click the
                  <span style="color:#ff3f3f;"><b>RANDOMIZER</b></span>
                  button, then click <span style="color:#ff9800;"><b>CONTINUE</b></span> to have the system build a patient
                  for you.
                </li>
              </ul>
              <v-row>
                <v-col
                  v-for="(avatar, index) in avatars" :key="index"
                  cols="4"
                >
                  <v-card
                    color="transparent"
                    @click="selectAvatar(avatar.id, false)"
                    style="padding: 130% 0 0"
                    :img="avatar.url"
                  >
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Player from "/src/components/player/Player.vue";

export default {
  name: "StepAvatar",
  components: {
    Player,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    avatars: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    video_id: {
      type: [String],
      required: false,
      default: "000000",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      case: (state) => state.case,
      caseData: (state) => state.caseData,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
      continueButtonEnabled: (state) => state.continueButtonEnabled,
    }),
    avatarList() {
      return this.avatars;
    },
  },
  created() {
    this.setIntroduction(true);
    this.clearAvatar();
  },
  methods: {
    ...mapMutations([
        "setAvatar",
        "setIntroduction",
        "setContinueButtonEnabled"
      ]),
    ...mapActions(["trackingAction", "setCaseData"]),
    clearAvatar() {
      this.setAvatar({});
    },
    avatarArea() {
      this.setIntroduction(false);
    },
    selectAvatar(avatarSelected, random = false) {
      let avatar = {}
      const fullAvatar = this.filterAvatarById(avatarSelected)
      avatar = Object.fromEntries(Object.entries(fullAvatar).filter(([_, v]) => v != null));
      avatar.name = avatar.firstname + ' ' + avatar.lastName
      avatar.random = random
      this.setContinueButtonEnabled(true);
      this.setAvatar(avatar);
      if (avatar.random) {
        this.trackingAction("Avatar Selection: " + this.avatar.id + ": Randomizer");
      } else {
        this.trackingAction("Avatar Selection: " + this.avatar.id + ": Manual");
      }
      this.clearCurrentCaseData();
    },
    clearCurrentCaseData() {
      this.setCaseData({});
    },
    filterAvatarById(id) {
      return this.avatars.find((avatar) => {
        if(avatar.id === id) return true
      })
    },
    randomAvatar() {
      let avatarsIds = this.avatars.map(avatar => {
        return avatar.id
      })
      const random = avatarsIds[Math.floor(Math.random() * avatarsIds.length)];
      this.selectAvatar(random, true);
    },
  },
};
</script>
<style>
  .randomizer-button {
    background: radial-gradient(at right center, rgb(203 0 0), rgb(119 13 21));
  }
</style>